(function($) {
    var audiojsscript = require('monitor/audio.min.js');
    var audiojs = audiojsscript.audiojs;

    // Events and handling for the global site header
    function MonitorArticles(el, options) {

        // defaults options:
        this.defaults = {
            'duration' : 10,
            'fadeSpeed' : 1000, // millis
        };

        // extending options:width: 198px;
        this.opts = $.extend({}, this.defaults, options);
        this.opts = $.extend({}, this.opts, $(el).data('options'));

        // the element:
        this.$el = $(el);
        $("<div class=\"next\" />").prependTo(this.$el).css({opacity: 0});
        $("<div class=\"current\" />").prependTo(this.$el).css({opacity: 0});

        // Items to be displayed
        this.queue          = [];
        this.waiting        = {};
        this.preventRender  = false;
        this.currentArticle = null;
        this.nextArticle    = null;

        window.derp = this;
    }

    // separate functionality from object creation
    MonitorArticles.prototype = {

        // initializes plugin
        init: function() {
            var self = this;
            this.refreshData(function() {
                var article = self.queue.shift();

                if (article) {
                    var $next = self.$el.find('.next');
                    $next.html(self.getHtml(article)).css('opacity', 0);

                    // Set this article as current because run will render it.
                    self.currentArticle = article;

                    self.run();
                }
            });
        },

        /**
         *
         */
        run: function() {
            console.log("run()");
            var self = this;

            if (this.queue.length == 0) {
                this.refreshData(function() {
                    if (self.queue.length == 0) {
                        console.log("Queue still empty after refresh, will check again in 10 sec");
                        window.setTimeout(function() { self.run() }, 10000);
                    } else {
                        console.log("Queue refilled");
                        self.run();
                    }
                });

                return;
            }

            if (this.preventRender) {
                window.setTimeout(function() { self.run() }, 5000);
                return;
            }

            this.renderNext();
        },

        /**
         *
         */
        renderNext: function() {
            var self = this;

            var article = this.queue.shift();

            if (article) {
                self.nextArticle = article;
                this.render(article);
            }

            if (typeof self.currentArticle == "object") {
                console.log("Displaying slide for " + self.currentArticle.duration + " sec");
                window.setTimeout(function() {
                    self.run()
                }, self.currentArticle.duration * 1000);
            }
        },

        /**
         *
         */
        renderAt: function(date, article) {
            var self = this;
            article.date = date;

            var now = new Date();
            var until = date.getTime() - now.getTime();

            if (article.id in this.waiting) {
                this.waiting[article.id].title = article.title;
                this.waiting[article.id].text = article.text;
                this.waiting[article.id].important = article.important;

                if (+date != +this.waiting[article.id].date) {
                    clearTimeout(this.waiting[article.id].timeout);

                    this.waiting[article.id].timeout = setTimeout(function(){
                        self.render(self.waiting[article.id], self.waiting[article.id].duration);
                    }, until);
                }

                return;
            }

            this.waiting[article.id] = article;
            this.waiting[article.id].timeout = setTimeout(function(){
                self.render(self.waiting[article.id], self.waiting[article.id].duration);
            }, until);
        },

        preventRenderFor: function(seconds) {
            console.log("prevent new rendering for " + seconds + " seconds");
            var self = this;
            this.preventRender = true;

            window.setTimeout(function() { self.preventRender = false}, seconds * 1000);
        },

        /**
         *
         */
        render: function(article, secondsToHold) {
            var self = this;
            if (secondsToHold) {
                this.preventRenderFor(secondsToHold);
            }

            var $current = self.$el.find('.current');
            var $next = self.$el.find('.next');

            $next.css('opacity', 1);

            $current.animate({
                'opacity': 0
            }, self.opts.fadeSpeed, function() {
                // Next is now displayed.
                // Add class as current.
                var $newCurrent = $next.addClass('current').removeClass('next');
                self.currentArticle = self.nextArticle;

                // This slide was current.
                // Remove current class.
                // Add next class, empty html and hide the slide.
                var $newNext = $current.addClass('next').removeClass('current').html('').css('opacity', 0).prependTo(self.$el);

                // Check if audio should be played at the newCurrent
                var $audioElement = $newCurrent.find('audio');
                if ($audioElement.length > 0) {
                    var audioPlayer = audiojs.create($audioElement);
                    audioPlayer[0].play();
                }

                // Prepare html for next slide
                $newNext.html(self.getHtml(article));
            });
        },

        /**
         *
         */
        getHtml: function(article) {
            var self                       = this;
            var source                     = self.getTemplate(article.template);
            var background                 = '';
            var fontColorCssString         = '';
            var hideDesignElementsCssClass = '';

            if (!source) {
                return;
            }

            // Background will be a solid background.
            // Either background provided in article,
            // white if hideDesignElements is checked or
            // default backgroundImage.
            if (typeof article.background != "undefined" && article.background != "") {
                background = article.background;
            } else if (article.hideDesignElements === true) {
                background = "#ffffff";
            } else {
                background = "url('/images/bakgrunn_2.gif')";
            }

            if (typeof article.fontColor != "undefined" && article.fontColor != "") {
                fontColorCssString = '; color:' + article.fontColor + ';';
            }

            if (article.hideDesignElements === true) {
                hideDesignElementsCssClass = 'hideDesignElements';
            }

            source = '<div class="' + hideDesignElementsCssClass + ' ' + article.cssClass + ' ' + article.template + '">' +
                '<div class="content" style="background: ' + background + fontColorCssString + '">' + source + '</div></div>';

            var template = Handlebars.compile(source);

            return template(article);
        },

        getTemplate: function(id) {
            return this.$el.find("#" + id).html();
        },

        refreshData: function(callback) {
            var self = this;
            $.get("/xmlhttprequest.php?service=monitor.articles&screen=" + window.monitor.id, function(data) {
                console.log("refreshData() : done");

                $(data).each(function(index, article) {
                    if (article.at) {
                        self.renderAt(new Date(article.at), article);
                    } else {
                        self.queue.push(article);
                    }
                });

                callback();
            });
        }
    };

    /**
     * Hand over the plugin to jQuery and make it available as "monitorArticles"
     */
    $.fn.monitorArticles = function(options) {
        var rev = null, l = this.length;
        if (l && this.each) {
            this.each(function() {
                rev = $(this).data('monitorarticles');
                if (typeof rev === 'undefined') {
                    rev = new MonitorArticles(this, options);
                    rev.init();
                    $(this).data('monitorarticles', rev);
                }
            });
        }

        if (l === 1) {
            return rev;
        }
    };
})(jQuery);
